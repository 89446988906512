const TransparentIcon = () => (
  <svg viewBox="0 0 54 54" width={54} height={54} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M45 15.6375V38.3625C44.64 38.295 44.2575 38.25 43.875 38.25C40.77 38.25 38.25 40.77 38.25 43.875C38.25 44.2575 38.295 44.64 38.3625 45H15.6375C15.705 44.64 15.75 44.2575 15.75 43.875C15.75 40.77 13.23 38.25 10.125 38.25C9.7425 38.25 9.36 38.295 9 38.3625V15.6375C9.36 15.705 9.7425 15.75 10.125 15.75C13.23 15.75 15.75 13.23 15.75 10.125C15.75 9.7425 15.705 9.36 15.6375 9H38.3625C38.295 9.36 38.25 9.7425 38.25 10.125C38.25 13.23 40.77 15.75 43.875 15.75C44.2575 15.75 44.64 15.705 45 15.6375Z"
      stroke="#F6B24F"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.75 10.125C15.75 13.23 13.23 15.75 10.125 15.75C9.7425 15.75 9.36 15.705 9 15.6375C6.435 15.12 4.5 12.8475 4.5 10.125C4.5 7.02 7.02 4.5 10.125 4.5C12.8475 4.5 15.12 6.435 15.6375 9C15.705 9.36 15.75 9.7425 15.75 10.125Z"
      stroke="#F6B24F"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M49.5 10.125C49.5 12.8475 47.565 15.12 45 15.6375C44.64 15.705 44.2575 15.75 43.875 15.75C40.77 15.75 38.25 13.23 38.25 10.125C38.25 9.7425 38.295 9.36 38.3625 9C38.88 6.435 41.1525 4.5 43.875 4.5C46.98 4.5 49.5 7.02 49.5 10.125Z"
      stroke="#F6B24F"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.75 43.875C15.75 44.2575 15.705 44.64 15.6375 45C15.12 47.565 12.8475 49.5 10.125 49.5C7.02 49.5 4.5 46.98 4.5 43.875C4.5 41.1525 6.435 38.88 9 38.3625C9.36 38.295 9.7425 38.25 10.125 38.25C13.23 38.25 15.75 40.77 15.75 43.875Z"
      stroke="#F6B24F"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M49.5 43.875C49.5 46.98 46.98 49.5 43.875 49.5C41.1525 49.5 38.88 47.565 38.3625 45C38.295 44.64 38.25 44.2575 38.25 43.875C38.25 40.77 40.77 38.25 43.875 38.25C44.2575 38.25 44.64 38.295 45 38.3625C47.565 38.88 49.5 41.1525 49.5 43.875Z"
      stroke="#F6B24F"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default TransparentIcon
